@import '../../styles/vars';

.content {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  background-color: $cBeigeLight;

  @media (max-width: 800px) {
    & {
      flex-direction: column-reverse;
    }
  }
}
