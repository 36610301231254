@import '../../styles/vars';

.link {
  text-decoration: none;
  color: $cBlueDark;

  &:hover {
    text-decoration: underline;
  }
}
