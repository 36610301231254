// Colors
$cWhite: #fff;
$cBlack: #333;
$cBeigeDark: #F9EBC8;
$cBeigeLight: #FEFBE7;
$cGreen: #DAE5D0;
$cBlue: #A0BCC2;
$cBlueDark: #71858A;

// Sizes
$bmu: 8px;
$wMax: 1024px;
$hTopbar: 80px;
