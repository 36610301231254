@import '../../styles/vars';

.topbar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: $hTopbar;
  padding: 0 2*$bmu;
  border-bottom: 1px solid $cBlue;
  background-color: $cBeigeDark;

  @media (max-width: 500px) {
    align-items: flex-start;
  }

  &__title {
    font-size: 32px;
    font-weight: 400;
    color: $cBlack;

    @media (max-width: 360px) {
      font-size: 28px;
    }
  }

  &__subtitle {
    margin-top: $bmu/2;
    font-size: 18px;
    font-weight: 400;
    color: $cBlack;

    @media (max-width: 360px) {
      font-size: 16px;
    }
  }

  &__switcher {
    position: absolute;
    top: 50%;
    right: 2*$bmu;
    transform: translateY(-50%);
  }
}
