@import '../../styles/vars';

.switcher {
  display: inline-flex;
  padding: $bmu/2 $bmu;
  border-radius: 4px;
  background-color: $cBeigeLight;

  &__item {
    padding: $bmu/2;
    border-radius: 4px;
    color: $cBlack;
    cursor: pointer;

    &_state_active {
      color: $cWhite;
      background-color: $cBlue;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
