@import '../../styles/vars';

.icon {
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: $bmu;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &__home {
    &::before {
      background-image: url('../../img/home.svg');
    }
  }

  &__email {
    &::before {
      background-image: url('../../img/email.svg');
    }
  }

  &__linkedin {
    &::before {
      background-image: url('../../img/linkedin.svg');
    }
  }

  &__github {
    &::before {
      background-image: url('../../img/github.svg');
    }
  }
}
