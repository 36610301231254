@import '../../styles/vars';

.plate {
  display: inline-flex;
  margin: $bmu/2;
  padding: $bmu;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: $cWhite;
  background-color: $cBlue;

  &:not(:first-child) {
    margin-left: $bmu;
  }
}
