@import '../../styles/vars';

.skills {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 2*$bmu;
  background-color: $cBeigeLight;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  &__block {
    flex-grow: 1;
    width: 100%;

    &:not(:first-child) {
      margin-left: 2*$bmu;

      @media (max-width: 800px) {
        margin-left: 0;
      }
    }
  }
}
