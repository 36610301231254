html, body, div, span, iframe, h1, h2, h3, h4, h5, h6, p, pre, img, strong, b, ol, ul, li, form, label, article, aside, footer, header, menu, nav, section {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  color: inherit;
  background: transparent;
  outline: 0;
}

body {
  line-height: 1;
}

article, aside, footer, header, menu, nav, section {
  display: block;
}

nav {
  ul {
    list-style: none;
  }
}

a {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  font-size: 100%;
  background: transparent;
}

input, select {
  vertical-align: middle;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

button {
  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}
