@import '../../styles/vars';

.contacts {
  align-self: center;
  width: 200px;
  padding: 4*$bmu 2*$bmu;
  border-right: .5px solid $cBlack;
  font-size: 16px;
  font-weight: 400;
  color: $cBlack;

  @media (max-width: 800px) {
    border-top: .5px solid $cBlack;
    border-right: none
  }

  &__title {
    margin-bottom: 2*$bmu;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: $cBlack;
  }

  &__list {
    list-style-type: none;
  }

  &__item:not(:first-child) {
    margin-top: 2*$bmu;
  }
}
